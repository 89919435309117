@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/fonts/Montserrat-Bold.ttf") format("opentype");
  font-weight: normal;
}

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~nebular-icons/css/nebular-icons.css';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

;

html,
body {
  height: 100%;

  @media (min-width: 2560px) {
    font-size: 26px;
  }

  @media (min-width: 3860px) {
    font-size: 28px;
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-cell,
.mat-header-cell {
  padding: 1rem .5rem !important;
}

.change-password-modal-container {
  --border-radius: 1rem;
}

.is-active {
  color: #44ee69;
}

.is-not-active {
  color: var(--main-red);
}

.required-field {
  color: var(--main-red) !important;
}

:root {
  --default-box-shadow: 0px 0px 6px #00000029;
  --main-gradient-color-90: linear-gradient(90deg, #ed7944, #ee445c);
  --main-gradient-color-107: linear-gradient(107deg, #ed7944, #ee445c);
  --main-orange: #ed7944;
  --main-red: #ee445c;
  --main-gray: #2e2e2e;

  .confirm-cancel-button {
    text-transform: capitalize !important;
    font-size: .7rem !important;
  }

  .action-button:hover {
    color: var(--main-orange) !important;
  }

  ion-spinner,
  .large-spinner {
    height: 10rem;
    width: 10rem;
  }

  ion-spinner,
  .medium-spinner {
    height: 5rem;
    width: 6rem;
  }

  ion-spinner,
  .small-spinner {
    height: 0.7rem;
    width: 1rem;
  }

  ion-spinner,
  .white-spinner {
    color: white;
  }

  ion-spinner,
  .orange-spinner {
    color: #ee445c;
  }

  .mat-dialog-container {
    width: 65% !important;
    height: auto !important;

    margin-left: 23% !important;
  }
}

table {
  th {
    font-family: 'Montserrat-Bold' !important;
    color: #707070 !important;
    text-align: center;
    font-size: .9rem !important;

    div {
      justify-content: center;
    }
  }

  td {
    font-family: 'Montserrat' !important;
    color: #707070 !important;
    text-align: center;

    a {
      background: transparent !important;
      border: none !important;
      color: #707070 !important;
    }
  }
}

.landing-page-modal-container::part(content) {
  height: auto;
  border-radius: 0.6rem;

  @media screen and (max-width: 768px) {
    border-radius: 1.6rem 1.6rem 0 0;

    min-height: 45vh;
    max-height: auto;

    position: absolute;
    bottom: 0;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}